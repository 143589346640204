.container{
    padding: 0 2rem;
    display: grid;
    margin-top: 6rem;
    grid-template-columns: 1fr 3fr 1fr;
}
.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.wrapper>img{
    width: 30rem;
    position: absolute;
    bottom: 0;

}
.bluecircle{
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: linear-gradient(135.74deg, #0bb5ff 14.09%, #6dd3fe 83.81%);
}
.cart2{
    position: absolute;
    bottom: 25%;
    right: -2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.cart2>svg{
    width: 30px;
    height: 30px;
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid var(--black);
}
.signup{
    display: flex;
    gap: 1rem;
    background-color: #fff;
    align-items: center;
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 15px;
    box-shadow: var(--shadow1);
}
.signup>:nth-child(1){
    display: block;
    width: 6rem;
}
.signup>:nth-child(2){
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    border: 2px solid skyblue;
    align-items: center;
    padding: 5px;
    justify-content: center;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: #000;
}
.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}
.text2>:last-child{
    display: block;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.traffic>:first-child{
    font-size: 2.5rem;
    font-weight: 800;
}
.traffic>:last-child{
    text-transform: capitalize;
}

.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customers>:first-child{
    font-size: 2.5rem;
    font-weight: 800;
}
.customers>:last-child{
    text-transform: capitalize;
}

@media screen and (max-width: 856px){
    .text1, .text2>:nth-child(2){
        font-size: .9rem;
        text-align: justify;
    }
    .text2>:first-child, .traffic>:first-child, .customers>:first-child{
        font-size: 1.5rem;
        text-align: left;
    }
    .bluecircle{
        position: absolute;
        width: 70%;
        height: 100%;
    }
    .wrapper>img{
        width: 70%;
        height: 100%;
    }
    
    .container{
        margin-top: 1rem;
        grid-template-areas: 
        'left center center'
        'right right right';
    }

    .container>:first-child{
        grid-area: left;
        grid-template-rows: one;
        gap: 8rem;
    }
    .container>:nth-child(2){
    grid-area: center;
    }
    .container>:last-child{
    grid-area: right;
    display: flex;
    justify-content: space-around;
    margin: 2rem 0;
    }
}
@media screen and (max-width: 640px){
    .bluecircle{
        top: 0;
        width: 16rem;
        height: 72%;
    }
    .wrapper>img{
        top: 3rem;
        width: 12rem;
        height: 15rem;
    }
    .cart2{
        transform: scale(0.7);
        bottom: 0;
        right: -3rem;
    }
}